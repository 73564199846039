<template>
	<v-sheet flat>
		<p>Beginnen Sie Ihren ersten Schritt bei inngo und wählen Sie Ihre eigenen Fachabteilungen aus.</p>
		<v-btn color="primary" to="/assessment-config/departments">{{mwtr.DepartmentConfig}}</v-btn>
	</v-sheet>
</template>

<style lang="less">
	.v-stepper__step {
		cursor: pointer;
	}

	.assessment-config {
		box-shadow: none;
	}
</style>

<script type="text/javascript">
	export default {
		name: "AssessmentConfig", 
		props: {
			
		},
		data: () => {
			return {
				saved: false,
				step: 1, 
				selectedDepartments: [],
				departmentConfig: {},
				hidden: []
			}
		},
		
		created(){
			this.$store.dispatch("tags/fetchAll");
		}

	}
// </script>"
<template>

	<v-sheet class="pl-6">
		<v-row>
			Admin dashboard area
		</v-row>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">

	export default {
		name: "AdminDashboard", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		components: {
		},
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		created(){
			
		}

	}
// </script>"
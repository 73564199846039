<template>
	<div class="home">
			Herzlich Willkommen bei Inngo - Ihrem Digitalisierungsassessment!
	</div>
</template>

<script>

	export default {
		name: 'Home',
	}
</script>

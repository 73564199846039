<template>
	<v-sheet flat>
		<!-- <NewUserInformation v-if="user.new"></NewUserInformation> -->
		<AssessmentConfig v-if="canAccessAssessment && !assessment.config">
		</AssessmentConfig>
		<template v-else>
			<template v-if="canAccessAssessment">
				<LeadWelcome></LeadWelcome>
			</template>
			<template v-if="!canAccessAssessment && canAccessData">
				<v-btn to="/results" color="primary">{{mwtr.ResultsNavItem}}</v-btn>
			</template>
			<template v-if="!canAccessAssessment && !canAccessData">
				<NoSections></NoSections>
			</template>
		</template>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import NewUserInformation from "@/components/user/content/NewUserInformation";
	import AssessmentConfig from "@/components/user/assessment-config/AssessmentConfig";
	import LeadWelcome from "@/components/user/content/LeadWelcome"
	import NoSections from "@/components/user/content/NoSections";

	export default {
		name: "UserDashboard", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			LeadWelcome, 
			// NewUserInformation,
			AssessmentConfig,
			NoSections 
		},
		computed: {
			...Vuex.mapState({
				user: state => state.user, 
				assessment: state => state.assessment,
				// currentOrganisation: state => state.currentOrganisation,
				// organisation: state => state.organisation
			}), 
			canAccessAssessment(){
				return this.$store.getters.canAccessAssessment;
			},
			canAccessData(){
				return this.$store.getters.canAccessData;
			},
			// hasAssessment(){
				// return this.organisation.assessments.length ? true : false;
			// }
		},
		// methods: {
		// }

	}
// </script>"
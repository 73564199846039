<template>

	<v-sheet class="pa-3">
		<v-component :is="showDashboard"></v-component>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import AdminDashboard from "@/components/admin/AdminDashboard/"
	import UserDashboard from "@/components/user/UserDashboard/"
	import Home from "@/views/Home/"

	export default {
		name: "Dashboard", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		components: {
			AdminDashboard,
			UserDashboard,
			Home
		},
		computed: {
			...Vuex.mapState({
				user: state => state.user
			}),
			showDashboard(){
				if( this.user.role == "admin" || this.user.role == "superadmin" ){
					return "AdminDashboard"
				}
				if( this.user.role == "user" ){
					return "UserDashboard"
				}
				return "Home"
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"